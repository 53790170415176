import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { combineLatest, of } from 'rxjs';
import {
    distinctUntilChanged,
    map, switchMap
} from 'rxjs/operators';
import { RequestSetLanguage } from '@myphone';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ModalService } from '@webclient/modal/app-modal.service';
import { ExtensionBridge } from '@webclient/myphone/extension-bridge';
import { BridgesMap, GroupsMap } from '@webclient/myphone/bridge';
import { Group } from '@webclient/myphone/group';

function localToSystemLanguage(languageId: string) {
    switch (languageId) {
        case 'en-gb':
            return 'uk';
        case 'pt-br':
            return 'pt';
        case 'zh-cn':
            return 'zh';
        default:
            return languageId;
    }
}

function systemToLocalLanguage(phoneSystemLangId: string) {
    if (!phoneSystemLangId) {
        return phoneSystemLangId;
    }

    const languageIdLower = phoneSystemLangId.toLowerCase();
    switch (languageIdLower) {
        case 'uk':
            return 'en-gb';
        case 'pt':
            return 'pt-br';
        case 'zh':
            return 'zh-cn';
        default:
            return languageIdLower;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplyLangService {
    private localStorage = inject(LocalStorageService);
    private myPhoneService = inject(MyPhoneService);
    private modalService = inject(ModalService);

    public readonly sessionLanguage$ = this.myPhoneService.myPhoneSession.pipe(
        switchMap(session => combineLatest([
            session.systemParameters$.pipe(map(params => params.SystemLanguage), distinctUntilChanged()),
            session.bridgesMap$.pipe(
                switchMap((bridges : BridgesMap) => {
                    const localBridge = Object.values(bridges).find((bridge: ExtensionBridge) => bridge.id === 'local');
                    return localBridge?.groupsMap$ ?? of(undefined);
                }),
                switchMap((groupsMap : GroupsMap | undefined) => {
                    const localGroups = groupsMap ? Object.values<Group>(groupsMap) : [];
                    const primary = localGroups.find(group => group.groupId.IsPrimaryGroup);

                    return primary ? primary.language$ : of('');
                }),
                distinctUntilChanged()
            ),
            session.myInfo$.pipe(
                map(info => info.Language),
                distinctUntilChanged(),
            )
        ])),
        map(([systemLang, groupLang, extensionLang]) => {
            const prioritizedLanguage = extensionLang || groupLang || systemLang || '';
            return systemToLocalLanguage(prioritizedLanguage);
        }),
        distinctUntilChanged(),
    );

    selectLanguage(phoneSystemLangId: string) {
        const lang = systemToLocalLanguage(phoneSystemLangId);

        // This will cause applyLanguage
        this.localStorage.store(LocalStorageKeys.Language, lang);
        // Store user preference in MyPhone
        this.requestSetSystemLanguage(lang);
    }

    private requestSetSystemLanguage(localLangId: string) {
        this.myPhoneService.get(new RequestSetLanguage({
            Language: localToSystemLanguage(localLangId)
        })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
    }
}
